import { z } from 'zod';
import { FinancialCenter } from 'types/FinancialCenter';

export enum StatusEnum {
  CREATED = 'Created',
  NOTIFIED = 'Notified',
  RECEIVED = 'Received',
  OPENED = 'Opened',
  NOTIFICATION_FAILED = 'NotificationFailed',
  SENT_IN_CRM = 'SentInCrm',
  WAITING_FOR_PROCESSING_IN_CRM = 'WaitingForProcessingInCrm',
  PROCESSING_IN_CRM = 'ProcessingInCrm',
  CLOSED_IN_CRM = 'ClosedInCrm',
  CANCELLED_IN_CRM = 'CancelledInCrm',
}

export enum TransactionCategoryEnum {
  SUBMISSION = 'submission',
  SERVICES = 'services',
  RENEWAL = 'renewal',
  AGIL_TPA = 'agilTpa',
  SELLING_TEAMS = 'sellingTeams',
  TRANSACTION = 'transaction',
  GENERAL = 'general',
}

export enum TransactionBusinessEnum {
  LIFE_INSURANCE = 'lifeInsurance',
  LIVING_BENEFITS = 'livingBenefits',
  PROTEK_SOLUTIONS = 'protekSolutions',
  MORTGAGE = 'mortgage',
  INVESTMENTS = 'investments',
  GROUP_INSURANCE = 'groupInsurance',
  OTHERS = 'others',
}

export const bidderSchema = z.object({
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
});

export const advisorSchema = z.object({
  fullname: z.string(),
  licenseNumber: z.string(),
});

export const transactionDataSchema = z.object({
  advisor: advisorSchema,
  demandTypes: z.array(z.string()).optional(),
  transactionType: z.string().optional(),
  transactionAmount: z.string().optional(),
  transactionDate: z.preprocess(arg => arg + 'Z', z.string().datetime()).optional(),
});

export const transactionDocumentSchema = z.object({
  filename: z.string(),
  contentUrl: z.string(),
});

export const transactionSchema = z.object({
  bidder: bidderSchema,
  publicId: z.string().uuid(),
  client: z.string().optional(),
  notes: z.string().optional(),
  notesForAdvisorOnly: z.string().optional(),
  categoryKey: z.string(),
  status: z.nativeEnum(StatusEnum),
  documents: z.array(transactionDocumentSchema),
  businessUnitKeys: z.array(z.string()),
  financialCenterKey: z.nativeEnum(FinancialCenter).or(
    z.preprocess(arg => {
      if (typeof arg === 'string' && arg.length === 0) {
        return undefined;
      }
    }, z.undefined()),
  ),
  createdDateTimeUtc: z.preprocess(arg => arg + 'Z', z.string().datetime()),
  transaction: transactionDataSchema.nullable().optional(),
});

export type Transaction = z.infer<typeof transactionSchema>;
export type TransactionDocument = z.infer<typeof transactionDocumentSchema>;
