import React from 'react';
import { useParams } from 'react-router-dom';
import BackArrow from 'components/shared/BackArrow';
import useGetTransaction from 'domain/transactions/hooks/useGetTransaction';
import { useTranslation } from 'react-i18next';
import TransactionDocumentItem from 'domain/transactions/components/TransactionDocumentItem';
import styles from 'styles/domain/transactions/unique-transaction-page.module.scss';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import moment from 'moment';
import titleStyles from 'styles/components/pages.module.scss';

function UniqueTransactionPage() {
  const { id } = useParams();
  const { data, isLoading } = useGetTransaction(id as string);

  const { t } = useTranslation('transactions');

  const { fieldsContainer, fieldContainer, label, spinnerContainer, container } = styles;
  const { title, titleNoBorder, titleWrapper } = titleStyles;

  return (
    <div>
      <div className={`${title} ${titleNoBorder} ${titleWrapper}`}>
        <BackArrow />
        <h1>{t('transaction')}</h1>
      </div>
      
      {isLoading && (
        <div className={spinnerContainer}>
          <LoadingSpinner />
        </div>
      )}
      {data && (
        <div className={container}>
          <div className={fieldsContainer}>
            <div className={fieldContainer}>
              <label className={label}>{t('table.advisor')}</label>
              <p>{`${data.bidder.firstname} ${data.bidder.lastname}`}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('table.sendDate')}</label>
              <p>{moment(data.createdDateTimeUtc).local().format('YYYY-MM-DD HH:mm')}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('table.status')}</label>
              <p>{t(`status.${data?.status}`)}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('businessDivision', { ns: 'sendToGc' })}</label>
              <p>{data?.businessUnitKeys.map(businessKey => t(businessKey, { ns: 'genericItem' })).join(', ')}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('category', { ns: 'sendToGc' })}</label>
              <p>{t(data?.categoryKey, { ns: 'common' })}</p>
            </div>

            {data.financialCenterKey && (
              <div className={fieldContainer}>
                <label className={label}>{t('financialCenter', { ns: 'sendToGc' })} </label>
                <p>
                  {t(data.financialCenterKey, {
                    ns: 'financialCenter',
                  })}
                </p>
              </div>
            )}

            <div className={fieldContainer}>
              <label className={label}>{t('advisorLicenseNumber', { ns: 'sendToGc' })}</label>
              <p>{data?.transaction?.advisor.licenseNumber} {data?.transaction?.advisor.fullname}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('transactionTypes', { ns: 'sendToGc' })}</label>
              <p>{t(data?.transaction?.transactionType ?? '', { ns: 'crmTransaction' })}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('demandTypes', { ns: 'sendToGc' })}</label>
              <p>{data?.transaction?.demandTypes?.map(e => t(e, { ns: 'crmDemand' })).join(', ')}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('transactionAmount', { ns: 'sendToGc' })}</label>
              <p>{data?.transaction?.transactionAmount}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('transactionDate', { ns: 'sendToGc' })}</label>
              <p>{moment(data?.transaction?.transactionDate).local().format('YYYY-MM-DD HH:mm')}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('clientIdentification', { ns: 'sendToGc' })}</label>
              <p>{data?.client}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('notes', { ns: 'sendToGc' })}</label>
              <p>{data?.notes}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('notesForAdvisorOnly', { ns: 'sendToGc' })}</label>
              <p>{data?.notesForAdvisorOnly}</p>
            </div>

            <div className={fieldContainer}>
              <label className={label}>{t('files')}</label>
              {data?.documents.map((document, i) => (
                <TransactionDocumentItem key={i} document={document} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UniqueTransactionPage;
