import useGetProfileInfo from 'domain/profile/hooks/useGetProfileInfo';
import React from 'react';

const testerList = [
  'kgrenier@gcloutier.com',
  'fdeziel@gcloutier.com',
  'spaquin@gcloutier.com',
  'fgoulet@gcloutier.com',
  'fbruneau@gcloutier.com',
  'btrudel@gcloutier.com',
  'earsenault@gcloutier.com',
  'mgaudet@gcloutier.com',
  'bmoffet@gcloutier.com',
  'njourdain@gcloutier.com',
  'GCloutierAdmin@gcloutier.ca',
  'martin.danis@groupedussault.ca',
  'p-l.dufour@servicesfinancierspld.com',
  'info@katialalancette.com',
  'f.tremblay@tremblayfinance.com',
  'pdupuis@sfpauldupuis.com',
  'js@sfrioux.com',
  'nadine@blsf.ca',
  'mjlanthier@blaquiereassocies.com',
  'mathieu.marcil@marcilgestionfinanciere.com',
  'gabrielferland@sfbbf.ca',
  's.poulin@actiprima.com',
  'bernard@boulanger.ws',
  'jean-guy@rioux.ws',
  'cdionne@cs-la.ca',
  'lbeaumier@pbsf.ca',
  'dlavoie@blaquiereassocies.com',
  'robert.calvert@sfrc-inc.ca',


  'info@sfblinc.ca',
  'mcdougall@bournefinancialgroup.com',
  'jturmel@turmelservicesfinanciers.com',
  'nlaliberte@sfdl.ca',
  'daniel.lheureux@dlheureux.com',
  'jeanfrancois.choiniere@solutionsfg.com',
  'jean-francois.dubuc@solutionsfg.com',
  'olivier.pastorel@solutionsfg.com',
  'sebastien.guay@solutionsfg.com',
  'rozon@videotron.ca',
  'yann@servicesfinanciersyb.com',
  'service@jamarcoux.ca',
  'anthonyplourde43@gmail.com',
  'sylvain.decoste@videotron.ca',
  'francis@francisseguin.com',
  'samuel@sfseguin.com',
  'info@sfseguin.com',
  'louis-samuel.nolin@gppfortune.com',
  'serge.nolin@gppfortune.com',
  'gilles.viel@videotron.ca',
  'service@ericfinances.ca',
  'jdesjardins@harmoniefinanciere.ca',
  'glabbe@harmoniefinanciere.ca ',
  'jdesjardins@harmoniefinanciere.ca',
  'ppare@harmoniefinanciere.ca',
  'info@finaplus.ca',
  'justin.cormier@finaplus.ca',
  'mcarpentier@placementsdmc.ca',
  'dcarpentier@placementsdmc.ca',
  'jfbconseils@videotron.ca',
  'alexandre@dupuisconseiller.ca',
  'claudelachapelle@videotron.ca',
  'michel@micheldupuis.com',
  'benoitgauvin@bggestiondepatrimoine.com',
  'fp@fredericpilon.ca',
  'pascal.doucetfinances@gmail.com',
  'fcardinal@groupefinancierlc.ca',
  'mlacombe@groupefinancierlc.ca',
  'suivis@manera.ca',
  'jocelyncyrenne@gmail.com',
  'danic.lussier@sensrl.ca',

  'assurances@placementsdmc.ca',
  'cbdubois@dulude.co',
  'sebast.drouin@gmail.com',
  'julien@julienfc.ca',
  'hubert.laterreur@gmail.com',
  'loic.planiassure@gmail.com',
  'louis.fitz@outlook.com',
  'emaurice@bessette-assurances.com',
  'ffortier@bessette-assurances.com',
  'mrobert@bessette-assurances.com',
];

function useIsTester() {
  const info = useGetProfileInfo();
  const email = info.data?.email.toLowerCase();
  const indexOf = testerList.findIndex(item => email === item.toLowerCase());

  return indexOf !== -1;
}

export default useIsTester;
