import { z } from 'zod';
import { SelectOption, selectOptionSchema } from 'schemas/selectOption';
import { Errors } from 'types/errors';
import { TransactionBusinessEnum, TransactionCategoryEnum } from 'types/transaction';
import { equisoftDocumentSchema } from 'domain/equisoft/schemas/equisoftDocumentSchema';

const sendToGCInputSchema = z
  .object({
    selectedBusiness: selectOptionSchema,
    selectedCategory: selectOptionSchema,
    selectedFinancialCenter: selectOptionSchema.optional(),
    notes: z.string().optional(),
    notesForAdvisorOnly: z.string().optional(),
    clientName: z.string().min(1, { message: Errors.REQUIRED }).max(95, { message: Errors.CLIENT_MAX_LENGTH }),
    transactionTypes: z.array(selectOptionSchema).optional(),
    demandTypes: z.array(selectOptionSchema).optional(),
    transactionAmount: z.string().optional(),
    transactionDate: z.date(),
    advisorLicenseNumber: selectOptionSchema.optional(),
    files: z.array(z.instanceof(File)).optional(),
    sendToEquisoft: z.boolean(),
    clientNameFromEquisoft: selectOptionSchema.optional(),
    equisoftDocuments : z.array(equisoftDocumentSchema).optional(),
  })
  .superRefine((val, ctx) => {
    const isInvestAndTransaction =
      val.selectedCategory.value === TransactionCategoryEnum.TRANSACTION &&
      val.selectedBusiness.value === TransactionBusinessEnum.INVESTMENTS;
      
    if (isInvestAndTransaction) {
      if (val.files?.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: Errors.REQUIRED,
          path: ['files'],
        });
      }

      if (val.advisorLicenseNumber === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: Errors.REQUIRED,
          path: ['advisorLicenseNumber'],
        });
      }

      if (val.demandTypes === undefined || val.demandTypes?.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: Errors.REQUIRED,
          path: ['demandTypes'],
        });
      }

      if (val.transactionTypes === undefined || val.transactionTypes?.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: Errors.REQUIRED,
          path: ['transactionTypes'],
        });
      }
    }
  })
  .refine(validateSelectedFinancialCenterPresence, {
    message: Errors.REQUIRED,
    path: ['selectedFinancialCenter'],
  });

type SendToGCInput = z.infer<typeof sendToGCInputSchema>;

function needSelectedFinancialCenter({
  selectedCategory,
}: {
  selectedBusiness: SelectOption;
  selectedCategory: SelectOption;
}) {
  return (
    selectedCategory.value === TransactionCategoryEnum.SELLING_TEAMS
  );
}

function validateSelectedFinancialCenterPresence({
  selectedBusiness,
  selectedCategory,
  selectedFinancialCenter,
}: {
  selectedBusiness: SelectOption;
  selectedCategory: SelectOption;
  selectedFinancialCenter?: SelectOption;
}) {
  return needSelectedFinancialCenter({ selectedBusiness, selectedCategory }) ? !!selectedFinancialCenter : true;
}

export type { SendToGCInput };
export {
  sendToGCInputSchema,
  needSelectedFinancialCenter
};
